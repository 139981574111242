import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';

import { getAppConfig } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

declare let gtag: Function;

const MIXPANEL_GROUP_KEY = 'tenantId';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private mixpanelEnabled: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  trackEvent(category: string, label: string, properties: Record<string, any> = {}) {
    gtag('event', 'click', {
      event_category: category,
      event_label: label,
      // value,
    });

    this.mixpanelTrack(label, {
      ...properties,
      tenantId: this.authService.tenantId,
      pageContext: category,
    });

    this.juneTrack(label, {
      ...properties,
      pageContext: category,
      tenantId: this.authService.tenantId,
    });
  }

  juneTrack(event, properties) {
    if ((window as any).analytics?.track) {
      (window as any).analytics.track(event, properties, {
        context: { groupId: this.authService.tenantId },
      });
    }
  }

  mixpanelTrack(event, properties) {
    if (!this.mixpanelEnabled) {
      return;
    }

    mixpanel.track(event, properties);
  }

  initMixpanel() {
    // Skip analytics if we are impersonating someone.
    if (this.authService.idTokenResult?.claims?.impersonatedBy) {
      return;
    }

    this.mixpanelEnabled = true;

    // Init mixpanel
    mixpanel.init(environment.mixPanelToken, { debug: !environment.production });

    const tenantId = this.authService.tenantId;
    const profile = this.authService.profile;
    const tenant = this.authService.tenant;

    if (!profile) {
      return;
    }

    mixpanel.identify(this.authService.userId);
    mixpanel.people.set({
      tenantId,
      isTenantAdmin: this.authService.isCompanyAdmin,
      $email: profile.email,
      $name: profile.name,
    });

    if (tenant) {
      mixpanel.set_group(MIXPANEL_GROUP_KEY, tenantId);
      mixpanel.get_group(MIXPANEL_GROUP_KEY, tenantId).set({
        name: tenant.name,
      });
    }
  }

  init(trackingId: string) {
    this.initMixpanel();

    // Init google analytics
    if (!getAppConfig().googleAnalyticsTrackingId) {
      console.log('No tracking id; google analytics disabled');
      return;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', trackingId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
